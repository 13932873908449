<template>
  <div class="relative w-full flex px-12 video-wrapper">
    <div class="h-full border-r base-border-grey-300">
      <div class="flex flex-col justify-between w-96 h-full">
        <p class="font-sans text-base text-white px-4 pt-12 pb-8 border-b base-border-grey-300">
          Define your discovery by selecting videos of which you would like to compare the audience to.
        </p>
        <div class="h-full px-4 overflow-y-auto">
          <p class="font-sans text-base text-white my-8">
            Videos added for Analysis
          </p>
          <div 
            v-for="video in addedVideos"
            :key="video.id"
            class="pb-3.5 mb-3.5 border-b border-dashed base-border-grey-300"
          >
            <div class="dark-bg relative">
              <div class="w-1/2">
                <img 
                  :src="`https://img.youtube.com/vi/${video.snippet.resourceId.videoId}/mqdefault.jpg`" 
                  class="rounded not_draggable"
                >
              </div>
              <div class="w-full flex justify-end absolute bottom-0">
                <div class="w-4/5 flex items-center mt-2 py-2.5 pl-2.5 pr-4 relative dark-bg rounded">
                  <p class="text-sm text-white font-sans">
                    {{ video.snippet.title | truncate(30) }}
                  </p>
                  <button 
                    @click="changeVideoState(video.snippet.resourceId.videoId)"
                    type="submit"
                    class="flex items-center justify-center text-sm h-5 w-5 rounded-full base-bg-darker-170 ml-2"
                  >
                    <span class="text-xs font-monoDemi text-white">
                      <closeIcon/>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-8">
          <div class="flex items-end px-4">
            <p class="text-white mr-4 leading-none">Comments</p>
            <div class="w-full">
              <p class="text-center font-mono text-xs base-font-gray-lighter">{{ commentersCounter }}</p>
              <div class="w-full progress h-3.5 bg-gray-200 rounded-lg base-bg-darker-180 mt-1">
                <div 
                  role="progressbar" 
                  aria-valuemin="0" 
                  aria-valuemax="600" 
                  :aria-valuenow="(commentersCounter/maximumCommenters) * 100" 
                  class="flex h-full bg-gray-900 rounded-lg bg-linkColor" 
                  :style="{
                    width: `${(commentersCounter/maximumCommenters) * 100}%`
                  }"
                ></div>
              </div>
            </div>
          </div>
          <button 
            :disabled="loading || commentersCounter == 0"
            @click="startAnalysisLocal"
            type="submit"
            class="flex justify-between items-center h-7 mt-8 ml-4 font-monoDemi inline-block text-xs px-3 h-7 rounded-2xl whitespace-nowrap text-black"
            :class="[ 
              { 'disabled:opacity-50': loading || commentersCounter == 0 }, 
              { 'base-bg-darker-900': loading || commentersCounter == 0 },
              { 'bg-white': commentersCounter > 0 }, 
            ]"
          >
            <span>Start Analysis</span>
            <Loader 
              v-if="loading"
              :animation="true"
              classes="w-4 h-4 ml-3"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <div class="flex h-full">
        <template v-if="Object.keys(sortedVideos).length">
          <div 
            v-for="(value, key) in sortedVideos"
            :key="key"
            class="border-r base-border-grey-300"
          >
            <div class="flex flex-col w-150 h-full px-4">
              <div class="border base-border-grey-300 rounded py-1.5 pr-4 mt-10">
                <div class="overflow-y-hidden flex items-center">
                  <template v-if="key == 'mixed'">
                    <div class="-ml-2 w-16 h-16 relative rounded-full bg-white">
                      <div class="absolute w-full h-full top-0 left-0 channel-avatar"></div>
                    </div>
                    <div class="ml-3">
                      <p class="w-full font-sans text-[18px] text-white">Mixed</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="-ml-2 w-16 h-16 relative">
                      <img 
                        :src="getChannelAvatar(key)" 
                        class="rounded-full not_draggable"
                      />
                      <div class="absolute w-full h-full top-0 left-0 channel-avatar"></div>
                    </div>
                    <div class="ml-3">
                      <p class="w-full font-sans text-[18px] text-white">
                        {{ getChannelTitle(key) }}
                      </p>
                      <!-- <p class="font-sans text-[13px] text-white">dummy text, dummy text (showing dummy text)</p> -->
                    </div>
                  </template>
                </div>
              </div>
              <div class="flex items-center justify-end mt-3 mb-5">
                <p 
                  @click="addAll(key)"
                  class="font-sans text-sm text-white cursor-pointer"
                >
                  Add All Videos
                </p>
                <p 
                  @click="deselectAll"
                  class="font-sans text-sm text-white cursor-pointer ml-4"
                >
                  Deselect All
                </p>
              </div>
              <div class="overflow-y-auto">
                <div 
                  v-for="video in value"
                  :key="video.id"
                  class="flex items-center mb-5"
                >
                  <img 
                    :src="`https://img.youtube.com/vi/${video.snippet.resourceId.videoId}/mqdefault.jpg`" 
                    class="rounded not_draggable w-1/4 mr-5"
                  >
                  <div class="dark-bg w-3/4 relative">
                    <p class="w-full text-base text-white font-sans">
                      {{ video.snippet.title | truncate(30) }}
                    </p>
                    <p class="base-font-gray-300 text-sm font-sans">
                      {{ formatDate(video.snippet.publishedAt) }} ago
                    </p>
                    <div class="absolute flex top-0 right-0">
                      <button 
                        @click="showModal(video.snippet.resourceId.videoId)"
                        :disabled="loading"
                        type="submit"
                        class="flex justify-between items-center inline-block px-2 h-5 bg-transparent rounded base-bg-darker-120"
                      >
                        <span class="text-xs-max font-sans base-font-gray-lighter-400">Watch</span>
                        <Loader 
                          v-if="loading"
                          :animation="true"
                          classes="w-4 h-4 ml-3"
                        />
                      </button>
                      <button 
                        v-if="!video.isAdded && (commentersCounter <= maximumCommenters)"
                        @click="changeVideoState(video.snippet.resourceId.videoId)"
                        :disabled="loading"
                        type="submit"
                        class="flex justify-between items-center inline-block px-2 h-5 bg-transparent rounded base-bg-darker-120 ml-1.5"
                      >
                        <span class="text-xs-max font-sans base-font-gray-lighter-400">Add</span>
                        <Loader 
                          v-if="loading"
                          :animation="true"
                          classes="w-4 h-4 ml-3"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
   

    <div class="fixed bottom-8 right-24">
      <div class="flex items-center">
        <p class="font-sans text-base base-font-gray-lighter-130 mr-6">
          Add another channel, 
          <br>
          video or playlist
        </p>
        <button 
          class="flex items-center justify-center font-grey-dark-200 base-bg-darker rounded-full w-16 h-16"
          @click="showModalAddVideo"
        >
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 19.166V27.6665C27 29.231 25.731 30.5 24.1665 30.5H4.3335C2.769 30.5 1.5 29.231 1.5 27.6665V7.8335C1.5 6.269 2.769 5 4.3335 5H12.834" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.3301 13.34V2" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.6602 7.66992H30.0002" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
    <modal 
      name="youtube-modal" 
      :clickToClose="false"
      :classes="[ 'z-50', 'bg-transparent', 'discoveryPlayerWrap' ]"
      :width="'75%'"
      :height="'75%'"
    >
      <closeIcon
        class="cursor-pointer bg-white rounded-full absolute top-0 right-0"
        @close="hideModal"
      />
      <youtube 
        ref="youtube"
        class="discoveryPlayer rounded-md"
        width="100%" 
        height="100%" 
        frameborder="0"
        sandbox="allow-scripts allow-same-origin allow-presentation" 
        :video-id="watchVideoId" 
        :player-vars="playerVars"
      />
    </modal>
    <modal 
      name="add-video-modal" 
      :clickToClose="false"
      :classes="[ 'z-50', 'bg-transparent', 'discoveryPlayerWrap' ]"
      :width="'50%'"
      :height="'50%'"
    >
      <closeIcon
        class="cursor-pointer bg-white rounded-full absolute top-0 right-0"
        @close="hideModalAddVideo"
      />
      <form 
        @submit.prevent="submitAdd"
        class="bg-white h-full w-full flex flex-wrap items-center justify-center"
      >
        <div class="w-1/2">
          <p class="w-full font-sans text=xl text-black">Add a Channel ID or URL</p>
          <input 
            v-model="link"
            id="link" 
            name="link"  
            class="h-8 px-3 mt-10 base-bg-darker-700 rounded w-full text-sm text-black placeholder-black outline-none shadow-black"
            placeholder="YouTube channel URL or ID"
            required
          >
          <div class="w-full flex justify-end mt-6">
            <button
              type="submit"
              class="font-monoDemi text-sm text-black px-6 py-1.5 base-bg-darker-160 rounded-2xl"
            >
              Add this Channel
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
  import Loader from '@/components/Loader'
  import closeIcon from '@/components/icons/close-icon'
  import { formatDate } from '@/helpers/formats'

  export default {
    props: {
      videos: {
        type: Array,
        default: () => []
      },
      addedVideos: {
        type: Array,
        default: () => []
      },
      commentersCounter: {
        type: Number,
        default: 0
      },
      maximumCommenters: {
        type: Number,
        default: 600
      },
      loading: {
        type: Boolean,
        default: false
      },
      channelAvatar: {
        type: String,
        default: ''
      },
      addData: Function,
      addedChannels: {
        type: Array,
        default: () => {[]}
      }
    },
    components: {
      Loader,
      closeIcon
    },
    data(){
      return {
        playerVars: {
          cc_load_policy: 1,
          iv_load_policy: 3,
          controls: 1,
          playsinline: 1,
          rel: 0,
          modestbranding: 1,
          autoplay: 0,
          widgetid: 1,
          allowfullscreen: 1
        },
        watchVideoId: '',
        link: '',
        sortedVideos: {}
      }
    },
    methods: {
      formatDate: formatDate,
      changeVideoState(videoId){
        this.$emit('changeVideoState', videoId)
      },
      addAll(key){
        const items = JSON.parse(JSON.stringify(this.sortedVideos[key]))
        this.$emit('addAll', items)
      },
      deselectAll(){
        this.$emit('deselectAll')
      },
      startAnalysisLocal(){
        this.$emit('startAnalysisLocal')
      },
      showModal(videoId){
        this.watchVideoId = videoId
        this.$modal.show('youtube-modal');
      },
      hideModal(){
        this.$modal.hide('youtube-modal');
      },
      hideModalAddVideo(){
        this.$modal.hide('add-video-modal');
      },
      showModalAddVideo(){
        this.$modal.show('add-video-modal');
      },
      submitAdd(){
        this.addData(this.link)
        this.hideModalAddVideo()
        this.link = ''
      },
      getChannelAvatar(key){
        const item = this.addedChannels.find(elem => elem.channelId == key)
        return item.avatar
      },
      getChannelTitle(key){
        const item = this.addedChannels.find(elem => elem.channelId == key)
        return item.title
      },
      createVideosScope(data){
        this.sortedVideos = {}
        let localData = JSON.parse(JSON.stringify(data))
        if(this.addedChannels.length){
          this.addedChannels.forEach(channel => {
            this.$set(this.sortedVideos, channel.channelId, localData.filter(elem => elem.snippet.channelId == channel.channelId))
            localData = localData.filter(elem => elem.snippet.channelId != channel.channelId)
          });
        }
        if(localData.length){
          this.$set(this.sortedVideos, 'mixed', localData)
        }
      } 
    },
    watch: {
      videos: {
        handler(data){
          this.createVideosScope(data)
        },
        deep: true
      }
    },
    mounted(){
      this.createVideosScope(this.videos)
    }
  }
</script>

<style lang="scss" scoped>
.channel-avatar{
  background: linear-gradient(277.7deg, #242424 26.02%, rgba(36, 36, 36, 0) 95.67%);
}
.video-wrapper{
  height: calc(100vh - 96px);
}
.shadowBox{
  background: linear-gradient(344.9deg, #4860B8 -8.8%, rgba(72, 96, 184, 0.35) 59.76%);
  z-index: 1;
}
.not_draggable{
  user-drag: none;
  -webkit-user-drag: none;
  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>